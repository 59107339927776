import React, { useEffect, useRef, useState } from "react"
import { css } from "@emotion/core"
import { StaticQuery, graphql } from "gatsby"
import Carousel from "nuka-carousel"
import styled from "@emotion/styled"
import Lightbox from "../components/Lightbox"

const pages = ["about", "benefits", "facilities", "beechcraft", "contact"]
const GET_PLANE = graphql`
  query GetPlanes {
    allFile(filter: { relativeDirectory: { eq: "planes" } }) {
      edges {
        node {
          childImageSharp {
            fixed {
              src
              width
            }
          }
        }
      }
    }
  }
`
const Button = styled("button")`
  background-color: var(--branding);
  color: var(--colors-branding);
  outline: none;
  border: none;
  border-radius: 5rem;
  cursor: pointer;
  transition: 0.4s ease all;
  &:hover {
    __transform: scale(1.2);
  }
`

const Planes = props => {
  const carouselRef = useRef()
  return (
    <StaticQuery
      query={GET_PLANE}
      render={({ allFile: { edges: planes } }) => (
        <Carousel
          ref={carouselRef}
          edgeEasing="easeOutCirc"
          slideWidth="400px"
          slidesToScroll={1}
          autoplay={true}
          wrapAround={true}
          renderBottomCenterControls={null}
          framePadding="3em"
          height="17em"
          renderCenterLeftControls={props => (
            <Button {...props} onClick={props.previousSlide}>
              &#9664;
            </Button>
          )}
          renderCenterRightControls={props => (
            <Button {...props} onClick={props.nextSlide}>
              &#9654;
            </Button>
          )}
        >
          {planes.map(
            ({
              node: {
                childImageSharp: {
                  fixed: { src, width },
                },
              },
            }) => (
              <div
                key={src}
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                `}
              >
                <Lightbox>
                  <img
                    src={src}
                    css={css`
                      height: 17rem;

                      /* max-width: max-content; */
                      transition: 0.8s ease all;
                      transform: scale(0.9);
                      box-shadow: var(--box-shadow-main);
                      &:hover {
                        transform: scale(1);
                        box-shadow: 0.4rem 0.5rem 1rem rgba(100, 100, 100, 0.4);
                      }
                    `}
                    onLoad={() => {
                      window.carouselRef = carouselRef.current
                      carouselRef?.current.setDimensions()
                    }}
                  />
                </Lightbox>
              </div>
            )
          )}
        </Carousel>
      )}
    />
  )
}

export default Planes
