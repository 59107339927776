import React, { useState } from "react"
import Modal from "react-modal"
import styled from "@emotion/styled-base"
import { findLastIndex } from "lodash"

const Stretch = styled("div")`
  width: 80vw;
  max-height: 80vh;
  img {
    max-width: 80vw;
    width: 100%;
    height: auto;
    transform: initial !important;
  }
`

const Lightbox = ({ children }) => {
  const [isOpen, setIsOpen] = useState()
  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          style={{
            overlay: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100vw",
              height: "100vh",
            },
            content: {
              inset: 0,
              padding: 0,
              display: "flex",
              height: "fit-content",
              position: "relative",
            },
          }}
        >
          <Stretch>{children}</Stretch>
        </Modal>
      )}
    </>
  )
}

export default Lightbox
